






































































































































































































































































































.framePage-body .infoDatas {
  line-height: 30px;
  .s2 {
    margin-right: 30px;
    margin-left: 5px;
  }
  .el-form-item {
      margin-bottom: 0;
  }
}
