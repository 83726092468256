.framePage-body .infoDatas {
  line-height: 30px;
}
.framePage-body .infoDatas .s2 {
  margin-right: 30px;
  margin-left: 5px;
}
.framePage-body .infoDatas .el-form-item {
  margin-bottom: 0;
}
